/* eslint-disable camelcase */
import * as ampli from '@amplitude/analytics-browser';
import {amplitudeEvent} from 'constants/amplitude';
import {SectionId as RegistrationSectionId} from 'api/protobuf/enrollment/protoTypes';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {questionConfig} from 'constants/questions';
import {populateMixpanelProperty, getCurrentProperties} from './mixpanel-utils';

export const amplitudeFlag =
    process.env.APP_ENV === 'production' ||
    process.env.APP_ENV === 'preprod' ||
    process.env.APP_ENV === 'integration';

let ampliInstance;

const getSectionIdValue = value => {
    return (
        Object.keys(RegistrationSectionId.Values).find(
            key => RegistrationSectionId.Values[key] === value
        ) || null
    );
};

export const getDisplayTitleFromSection = (sectionInfo, t) => {
    if (sectionInfo?.contentId) {
        const displayContentId = sectionInfo?.contentId;

        return t(`content:${displayContentId}.title`);
    } else if (sectionInfo?.errorsList) {
        const displayContentId = sectionInfo?.errorsList[0]?.contentId;

        return t(`content:${displayContentId}.title`);
    } else if (sectionInfo?.questionsToAsk?.nodeTitleId) {
        const displayQuestionId = sectionInfo?.questionsToAsk?.nodeTitleId;

        return t(`questions:$${displayQuestionId}.title`);
    } else if (sectionInfo?.questionsToAsk?.questionsList) {
        const questions = sectionInfo?.questionsToAsk?.questionsList || [];
        const {questionId} = questions[0] || {};
        const {id = -1} = questionId || {};
        const displayQuestionId = getQuestionNameById(id);

        if (questions.length > 1) {
            if (
                questionConfig[displayQuestionId].groupTitle &&
                t(`questions:${displayQuestionId}.groupTitle`) !== ''
            ) {
                return t(`questions:${displayQuestionId}.groupTitle`);
            } else {
                return t(`questions:${displayQuestionId}.title`);
            }
        } else {
            return t(`questions:${displayQuestionId}.title`);
        }
    }
};

export const AmplitudeUtils = {
    /**
     * Initialize the Ampli SDK. Call once when your application starts.
     */

    load() {
        ampliInstance = ampli.createInstance();

        ampliInstance.init(process.env.AMPLITUDE_API_KEY, {
            autocapture: {
                pageViews: false,
                sessions: false,
                formInteractions: false,
                fileDownloads: false,
                elementInteractions: false,
            },
        });
    },

    amplitudeIdentifyUser(userID) {
        // if oneapp, user id is member id
        // if legacy ,user id is uui
        ampliInstance.setUserId(userID);
    },

    trackBeforeInitialize() {
        ampliInstance.track(amplitudeEvent.BEFORE_INITIALIZE);
    },
    trackUserSSOSuccessful() {
        ampliInstance.track(amplitudeEvent.USER_SSO_SUCCESSFUL, {
            ...getCurrentProperties(),
            current_url: document?.URL ?? '',
        });
    },
    trackUserSSOFailed() {
        ampliInstance.track(amplitudeEvent.USER_SSO_FAILED, {
            ...getCurrentProperties(),
            current_url: document?.URL ?? '',
        });
    },
    trackUnableToContinue(registrationStep) {
        ampliInstance.track(amplitudeEvent.UNABLE_TO_CONTINUE, {
            ...populateMixpanelProperty(registrationStep, true),
            ...getCurrentProperties(),
            current_url: document?.URL ?? '',
        });
    },
    trackSessionTimeOut(registrationStep) {
        ampliInstance.track(amplitudeEvent.SESSION_TIME_OUT, {
            ...populateMixpanelProperty(registrationStep, true),
            ...getCurrentProperties(),
            current_url: document?.URL ?? '',
        });
    },
    trackPageLand(registrationStep) {
        const properties = {
            ...populateMixpanelProperty(registrationStep, true),
            ...getCurrentProperties(),
            section_id: getSectionIdValue(registrationStep?.context?.sectionId),
            current_url: document?.URL ?? '',
        };

        ampliInstance.track(amplitudeEvent.PAGE_LAND, properties);
    },
    trackPagePass(registrationStep) {
        const properties = {
            ...populateMixpanelProperty(registrationStep, true),
            ...getCurrentProperties(),
            section_id: getSectionIdValue(registrationStep?.context?.sectionId),
            current_url: document?.URL ?? '',
        };

        ampliInstance.track(amplitudeEvent.PAGE_PASS, properties);
    },
    trackClickedTeladocLogo() {
        ampliInstance.track(amplitudeEvent.CLICKED_TELADOC_LOGO, {
            ...getCurrentProperties(),
            current_url: document?.URL ?? '',
        });
    },
    trackUserPageHide() {
        ampliInstance.track(amplitudeEvent.PAGE_HIDE, {
            ...getCurrentProperties(),
            current_url: document?.URL ?? '',
        });
    },
};

export default AmplitudeUtils;
