import {useEffect} from 'react';
import Arg from '@livongo/arg';
import Config from 'config';
import {
    getRegistrationStepUrls,
    setRegistrationStepIndex,
    setUserSessionExpires,
} from 'utilities/utils';

const {idleTimerTimeout} = Config;

/*
 * Custom hook to handle navigation among registration steps,
 *  including browser actions i.e. backward, forward,
 *  refresh or manually input URL.
 * Go to initial step if no history yet.
 * Go to current step if the step is not visited step or,
 *  is a visited step but not in the history anymore.
 * Go to right step if the step is a visited step and,
 *  is still valid in the history.
 *
 * Navigation test cases.
 * Forward 1 step by clicking Next button within section.
 * Forward 1 step by clicking Next button cross sections.
 * Forward 1 step by clicking browser forward button within section.
 * Forward 1 step by clicking browser forward button cross sections.
 * Forward m steps by clicking browser forward button within section.
 * Forward m steps by clicking browser forward button cross sections.
 * Back 1 step by clicking Back button within section.
 * Back 1 step by clicking Back button cross sections.
 * Back 1 step by clicking browser backward button within section.
 * Back 1 step by clicking browser backward button cross sections.
 * Back m steps by clicking browser backward button within section.
 * Back m steps by clicking browser backward button cross sections.
 * Stay on the current step by browser refresh action or
 *  input the current URL manually.
 * Redirect back to the right step if manually input a URL
 *  which is a visited step other than current step.
 * Redirect back to current step if manually input a URL
 *  which is not a visited step.
 * Redirects when a user has gone multiple steps in the Registration process and add a different url like '/registration'
 */
const useNavigation = (pathname, history) => {
    useEffect(() => {
        // Persist oneApp query param in the URL
        // any truthy value will be converted to string 'true'
        const isOneApp = Boolean(Arg.get('oneapp'));

        if (isOneApp) {
            history.replace({
                search: `?oneapp=${isOneApp}`,
            });
        }

        /*
         * Set user session expiration and store it to localstorage or cookie.
         * This expiration will control whether we need to clean session or not.
         */
        setUserSessionExpires(Date.now() + idleTimerTimeout);

        // Get registration history from local storage or cookie.
        const stepUrls = getRegistrationStepUrls();

        if (stepUrls.length > 0) {
            // Get current step index from registration history.
            const currentStepIndex = stepUrls.indexOf(pathname);

            if (currentStepIndex !== -1) {
                // Update step index within registration history.
                setRegistrationStepIndex(currentStepIndex);
            }
        }
    }, [pathname, history]);
};

export default useNavigation;
