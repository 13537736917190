import Arg from '@livongo/arg';
import Cookies from 'universal-cookie';
import MixpanelUtils from '@livongo/utils/mixpanel';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import {languageCodeForSubmit, getLanguageCode} from 'i18n';
import {
    mixpanelGeneralError,
    mixpanelSessionRestart,
    setSuperAndPeopleProperties,
} from 'utilities/mixpanel-utils';
import {amplitudeFlag, AmplitudeUtils} from 'utilities/amplitude-utils';
import {REGISTRATION} from 'constants/app';
import {mixpanelEvent} from 'constants/mixpanel';
import {questionFieldTypes} from 'constants/questions';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {activateExperiment} from 'api/protobuf/enrollment/activateExperiment';
import {
    initializeEnrollment,
    submitAnswers,
} from 'api/protobuf/enrollment/services';
import PIWIKUtils from 'utilities/piwik-utils';
import {
    devConsoleLog,
    setApplication,
    getUserSessionExpires,
    getIsVisited,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    cleanSessionInfo,
    getUserUUID,
    setRegCode,
    setClientFriendlyName,
    getVWOVariant,
    setVWOVariant,
    getRegistrationContext,
    setRegistrationContext,
    setPartner,
    setCorrelationId,
    setIsRedirectFromTeams,
    checkIsTokenExpired,
} from 'utilities/utils';
import Config from 'config';

const {TEXT_FIELD, SINGLE_SELECT_ENUM} = questionFieldTypes;
const cookies = new Cookies();
const arg = Arg.all();
const savedLanguageFromCookies = cookies.get('user_preferred_language');
const languageFromUrl = Arg('locale')
    ? getLanguageCode(Arg('locale'))
    : getStoredLang();
const redirectFromTeams = Arg('fromTeams');
const lang = savedLanguageFromCookies || languageFromUrl;

const regcodeKeyList = [
    'regcode',
    'ccm_reg_code',
    'reg_code',
    'ccm_code',
    'registration_code',
    'ccm_registration_code',
    'ccmreg_code',
    'ccm_regcode',
    'ccmregistrationcode',
    'ccmcode',
    'registrationcode',
];

export const initialSubmitAnswer = async () => {
    const trackingIdParamName = 'trackingid';
    let regCodeParamName;

    Object.keys(arg).forEach(element => {
        if (regcodeKeyList.includes(element)) {
            regCodeParamName = element;
        }
    });

    if (arg[regCodeParamName] || arg[trackingIdParamName] || lang) {
        let answers = [];
        const oneAppFlag = Config.client.oneapp;

        if (arg[regCodeParamName] && !arg[oneAppFlag] && !arg.fromWelcomeBack) {
            const regCode = `${arg[regCodeParamName]}`.toUpperCase();

            // Set reg code to local storage or cookie.
            setRegCode(regCode);
            // TODO: Remove the hard coded client name after BE is ready.
            setClientFriendlyName('Your client');

            answers = [
                ...answers,
                {
                    questionId: {
                        id: QuestionId.Values.REG_CODE,
                        /*
                         * We have to hard code the version here
                         *  since this is the very beginning and
                         *  no way to get the value ahead of time.
                         */
                        version: 1,
                    },
                    type: TEXT_FIELD,
                    value: regCode,
                },
            ];
        }

        if (arg[trackingIdParamName]) {
            answers = [
                ...answers,
                {
                    questionId: {
                        id: QuestionId.Values.TRACKING_UUID,
                        /*
                         * We have to hard code the version here
                         *  since this is the very beginning and
                         *  no way to get the value ahead of time.
                         */
                        version: 1,
                    },
                    type: TEXT_FIELD,
                    value: arg[trackingIdParamName],
                },
            ];
        }

        if (languageCodeForSubmit[lang]) {
            answers = [
                ...answers,
                {
                    questionId: {
                        id: QuestionId.Values.LANGUAGE,
                        /*
                         * We have to hard code the version here
                         *  since this is the very beginning and
                         *  no way to get the value ahead of time.
                         */
                        version: 1,
                    },
                    type: SINGLE_SELECT_ENUM,
                    value: languageCodeForSubmit[lang],
                },
            ];
        }

        /*
         * We will not deal with the response
         *  since no matter it submitted successful or not,
         *  we will more forward.
         */

        if (answers.length > 0) {
            await submitAnswers(answers);
        }

        if (arg[regCodeParamName] && !arg[oneAppFlag]) {
            MixpanelUtils.track({
                event: mixpanelEvent.SUBMITTED_REG_CODE,
            });
        }

        if (arg[trackingIdParamName]) {
            MixpanelUtils.track({
                event: mixpanelEvent.SUBMITTED_TRACKING_ID,
            });
        }
    }
};

/*
 * We have client and partner id for customization.
 * client is used for BE grpc call, values are defined based on proto.
 *  Might import the value from proto in the future.
 *  Values are 1: reg5 (not set default to reg 5); 2: galaxy 3: salesforce; 4:sso (1 <> M partner); 5: oneapp
 * client is also used for Mixpanel log.
 *  However, the value currently set there is based on the client in the query string for "Salesforce",
 *  and hard coded "Reg5" for all other registration flows.
 * Current customized experiences based on client and partner id.
 *  1: OneApp
 *  2: Salesforce
 *  3: Teladoc - based on partner id and only for logo on header
 *  4: Normal REG 5
 * Where set value for client? Where set valeu for partner id?
 *  client set in setRegistrationContext during initialization.
 *  It must not set the value explicitly to reg5 for normal registration flow
 *   otherwise will overwrite the value for the salesforce refresh page use case;
 *   or has to add extra logic to handle the salesforce refresh page use case.
 *  It is also reset for salesforce and sso flow since the local storage get cleaned.
 */

export const initializeRegistration = async () => {
    try {
        /*
         * This function set the application name so that different application can use the same localstorage utility functions
         * without collisions between applcations by using different keys.  For instance with key for stepLoader, registration would be
         * registration_stepLoader and medopt would be medopt_stepLoader.
         */
        setApplication(REGISTRATION);

        /*
         * Get params from URL.
         * The params will include below information if applicable.
         * :utm string for mixpanel tracking;
         * :vwo  variants if use URL experiments;
         * :trackingId of registration;
         */
        const {focusedTabId} = getRegistrationContext() ?? {};

        /*
         * If no focusedTabId passed in within url, it is normal registration
         *  flow started by an end users. Otherwise, it is a client registration
         *  flow started by an admin/agent from Galaxy or Salesforce.
         * If focusedTabId is different than the current one, it means
         *  the admin/agent started a new session for a new member. Needs to
         *  clear all the cache stored in the local storage.
         */
        if (focusedTabId !== arg.focusedTabId) {
            cleanSessionInfo(true);

            const registrationContext = {
                // Must be salesforce now.
                client: Config.client.salesforce,
                focusedTabId: arg.focusedTabId,
                sessionId: arg.sessionId,
            };

            // Save the registration context into the local storage.
            setRegistrationContext(registrationContext);
        }

        /*
         * Get user session expiration time from localstorage or cookie.
         * If time is after that,
         * clean all the session information than start a new session.
         */
        const isVisited = getIsVisited() || false;
        const userSessionExpires = getUserSessionExpires() || 0;
        const isUserSessionExpired = Date.now() > userSessionExpires;
        const oneAppFlag = Config.client.oneapp;
        const isOneApp = Boolean(arg[oneAppFlag]);

        if (isVisited && isUserSessionExpired) {
            MixpanelUtils.identify(getUserUUID());
            mixpanelSessionRestart();

            cleanSessionInfo();
        } else if (!isVisited && isUserSessionExpired) {
            // check vwo split URL params, we should only expect one vwo param in the url
            for (const item in arg) {
                if (item.includes('vwo')) {
                    /* there should only have one experiment running at one time
                     * split VWO parameter value based on first underscore
                     * (e.g removeEligibilityNotConfirmed_VARIATION_1 will split into
                     * removeEligibilityNotConfirmed and VARIATION_1
                     */
                    const experimentValue = arg[item].split(/_(.*)/s);

                    setVWOVariant({
                        experimentName: experimentValue[0],
                        experimentGroup: experimentValue[1],
                    });
                }
            }

            const correlationId = 'cid';

            if (arg[correlationId]) {
                setCorrelationId(arg[correlationId]);
            }

            const partnerName = 'partner_id';
            const fromSso = 'fromSso';
            const fromWelcomeBack = 'fromWelcomeBack';

            if (arg[partnerName]) {
                setPartner(arg[partnerName]);
            }

            // only sso success needs to set the regcontext now
            if (arg[fromSso] === true || arg[fromWelcomeBack] === true) {
                if (isOneApp) {
                    setRegistrationContext({
                        client: Config.client.oneapp,
                    });
                } else {
                    setRegistrationContext({
                        client: Config.client.sso,
                    });
                }
            }

            if (redirectFromTeams) {
                setIsRedirectFromTeams(true);
            }

            setSuperAndPeopleProperties();
            MixpanelUtils.track({
                event: mixpanelEvent.BEFORE_INITIALIZE,
            });

            // Logging amplitude events only on non-prod envs for now

            if (amplitudeFlag && !arg.oneapp) {
                AmplitudeUtils.amplitudeIdentifyUser(getUserUUID());
            }

            AmplitudeUtils.trackBeforeInitialize();

            try {
                PIWIKUtils.trackBeforeInitialize();
            } catch (error) {
                mixpanelGeneralError({
                    name: 'Piwik Before Initialize Error',
                    location: 'index.async.catch',
                    additionalInfo: error,
                });
            }

            // Detect one app users without a valid token
            // These users to be redirected back to oneapp homepage to SSO again
            if (isOneApp && checkIsTokenExpired()) {
                const oneAppHomepageUrl =
                    process.env.ONEAPP_LOGIN_URL ?? Config.oneAppUrl;

                cleanSessionInfo(true);

                window.location.href = oneAppHomepageUrl;
            }

            // only do initializeEnrollment when it is regular flow or sso failed, no need for sso flow.
            if (
                (!arg[fromSso] && !arg[fromWelcomeBack]) ||
                arg[fromSso] === false
            ) {
                await initializeEnrollment(arg.user)
                    .then(response => {
                        window.grpcExpires = response;
                        const accessToken =
                            response?.authenticated?.accessToken?.token;

                        /*
                         * Store gRPC access token to localstorage or cookie
                         *  for sub-sequence calls.
                         */
                        setGrpcAccessToken(accessToken);
                        setGrpcAccessTokenTimeStamp(new Date().getTime());
                    })
                    .catch(e => {
                        // TODO: display proper error message.
                    });
            }

            const vwoExperiments = getVWOVariant() ?? {};

            if (vwoExperiments.experimentName) {
                await activateExperiment(
                    vwoExperiments.experimentName,
                    vwoExperiments.experimentGroup
                )
                    .then(response => response)
                    .catch(e => {
                        // TODO: display proper error message.
                    });
            }

            await initialSubmitAnswer();
        }
    } catch (error) {
        devConsoleLog(
            'components/registration.RegistrationUtils.async.catch',
            error
        );
        mixpanelGeneralError({
            name: 'Initialize Error',
            location: 'index.async.catch',
            additionalInfo: error,
        });
    }
};
